import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectCoverflow, Keyboard, Pagination } from 'swiper/modules';
import BlogHeroCard from './blog-hero-card';

import 'swiper/css';

const swiperSettings = {
  dots: true,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  delay: 10000,
  waitForAnimate: false,
};

function BlogHeroSlider({ postData }) {
  return (
    <div className="blog-list section d-block d-md-none">
      <Swiper
        className="blog-hero-swiper"
        centeredSlides
        loop
        modules={[Keyboard, Pagination, Autoplay]}
        pagination={{ dynamicBullets: true, clickable: true }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        spaceBetween={36}
        slidesPerView={1}
        keyboard={{ enabled: true }}>
        {postData.map((post, index) => (
          <SwiperSlide>
            <BlogHeroCard key={post.node + index} index={index} post={post.node} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default BlogHeroSlider;
