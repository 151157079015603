import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { formatReadingTime, renderContent } from '../../utils';

const aspectRatios = ['4 / 3', '16 / 9', '2 / 3', '7 / 8', '1 / 1', '3 / 5'];

export default function BlogListItem({ post, image, listIndex }) {
  const category = post.categories.nodes[0]?.name;

  // Calculate the aspect ratio using the listIndex
  const aspectRatio = aspectRatios[listIndex % aspectRatios.length];
  const readingTime = post.seo?.readingTime;

  return (
    <a key={post.id} href={`/blog/${post.slug}`} className="blog-list-item d-block">
      <GatsbyImage
        image={image}
        alt={post.featuredImage?.node.altText}
        style={{
          aspectRatio, // Apply the calculated aspect ratio
        }}
      />
      <div className="blog-list-item-content">
        <h4>{post.title}</h4>
        <div className="post-meta">
          {category && <span className="category">{category}</span>}
          {readingTime && (
            <span className="reading-time">
              <i className="far fa-clock" />
              {formatReadingTime(readingTime)}
            </span>
          )}
        </div>
        <p className="text-small" dangerouslySetInnerHTML={renderContent(post.excerpt)} />
      </div>
    </a>
  );
}
