import React, { useState, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import BlogListItem from './blog-list-item';
import { escapeRegex } from '../../utils';

function BlogList() {
  const data = useStaticQuery(graphql`
    query StaticBlogPostsWithFilters {
      allWpPost(
        sort: { date: DESC }
        filter: {
          categories: { nodes: { elemMatch: { name: { ne: "Success Stories" } } } }
        }
      ) {
        nodes {
          id: databaseId
          title
          date
          excerpt
          slug
          seo {
            readingTime
          }
          categories {
            nodes {
              name
              id: databaseId
            }
          }
          tags {
            nodes {
              name
              id: databaseId
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              altText
            }
          }
        }
        totalCount
      }
      allWpCategory {
        nodes {
          id: databaseId
          name
          count
        }
      }
      allWpTag {
        nodes {
          id: databaseId
          name
        }
      }
    }
  `);

  const allPosts = data.allWpPost.nodes;
  const categories = data.allWpCategory.nodes;
  const tags = data.allWpTag.nodes;

  const nonEmptyCategories = categories.filter(
    (cat) => cat.count > 0 && cat.name.toLowerCase() !== 'success stories',
  );
  const filteredTags = tags.filter((tag) => tag.name.toLowerCase() !== 'featured');

  const [filters, setFilters] = useState({
    keyword: '',
    category: '',
    tag: '',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 10;

  const filteredPosts = useMemo(
    () =>
      allPosts
        .filter((post) => {
          const matchesKeyword = filters.keyword
            ? new RegExp(escapeRegex(filters.keyword), 'i').test(post.title)
            : true;

          const matchesCategory = filters.category
            ? post.categories.nodes.some(
                (cat) => cat.id === parseInt(filters.category, 10),
              )
            : true;

          const matchesTag = filters.tag
            ? post.tags.nodes.find((tag) => tag.name === filters.tag)
            : true;

          return matchesKeyword && matchesCategory && matchesTag;
        })
        .slice(0, currentPage * itemsPerPage),
    [allPosts, filters, currentPage],
  );

  const totalFilteredPosts = useMemo(
    () =>
      allPosts.filter((post) => {
        const matchesKeyword = filters.keyword
          ? new RegExp(escapeRegex(filters.keyword), 'i').test(post.title)
          : true;

        const matchesCategory = filters.category
          ? post.categories.nodes.some((cat) => cat.id === parseInt(filters.category, 10))
          : true;

        const matchesTag = filters.tag
          ? post.tags.nodes.find((tag) => tag.name === filters.tag)
          : true;

        return matchesKeyword && matchesCategory && matchesTag;
      }).length,
    [allPosts, filters],
  );

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setFilters((prev) => ({ ...prev, keyword: searchTerm }));
    setCurrentPage(1);
  };

  const loadMorePosts = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const canLoadMore = currentPage * itemsPerPage < totalFilteredPosts;

  return (
    <div className="blog-list">
      <div className="blog-list-filters flex-column flex-md-row">
        <select
          id="category"
          className="form-control blog-select"
          name="category"
          value={filters.category}
          onChange={handleFilterChange}>
          <option value="">All Categories</option>
          {nonEmptyCategories.map((cat) => (
            <option key={cat.id} value={cat.id}>
              {cat.name}
            </option>
          ))}
        </select>
        <select
          id="tag"
          className="form-control blog-select"
          name="tag"
          value={filters.tag}
          onChange={handleFilterChange}>
          <option value="">All Tags</option>
          {filteredTags.map((tag) => (
            <option key={tag.id} value={tag.name}>
              {tag.name}
            </option>
          ))}
        </select>
        <form onSubmit={handleSearch} className="search-form">
          <input
            type="text"
            id="keyword"
            className="form-control"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button type="submit" className="search-button">
            <i className="far fa-search fa-lg" />
          </button>
        </form>
      </div>

      <div className="blog-list-container">
        {filteredPosts.map((post, index) => {
          const image = getImage(post.featuredImage?.node.localFile);
          return (
            <BlogListItem key={post.id} post={post} image={image} listIndex={index} />
          );
        })}
      </div>
      {filteredPosts.length === 0 && (
        <div className="blog-empty">
          <h2>No posts found.</h2>
          <p>
            There are no articles that match the current search query. Please adjust your
            search or refresh the page.
          </p>
        </div>
      )}

      {canLoadMore && (
        <div className="show-more">
          <button onClick={loadMorePosts} className="btn show-more-button">
            SHOW MORE
          </button>
        </div>
      )}
    </div>
  );
}

export default BlogList;
