import classNames from 'classnames';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { renderContent } from '../../utils';

function BlogHeroCard({ post, index }) {
  const image = post.featuredImage.node.localFile;
  const category = post.categories.nodes[0]?.name;

  return (
    <div key={index} className="blog-hero-card">
      <GatsbyImage
        className={classNames('img-fluid blog-hero-card-image')}
        image={getImage(image)}
        alt={post.title}
        objectFit="cover"
        layout="fullWidth"
        style={{
          width: '100%',
          aspectRatio: '16 / 9',
        }}
      />
      <div className="hero-card-content">
        <div>
          <p className="text-category">{category}</p>
          <h1 className="text-headline mb-2 mb-md-3">
            <Link to={post.slug}>{post.title}</Link>
          </h1>
          <p
            className="text-small"
            dangerouslySetInnerHTML={renderContent(post.excerpt)}
          />
        </div>
        <div className="hero-card-button">
          <Link to={post.slug} className="btn show-more-button">
            Continue Reading &raquo;
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BlogHeroCard;
