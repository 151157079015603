import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectCoverflow, Keyboard, Pagination } from 'swiper/modules';
import BlogHeroCard from './blog-hero-card';

import 'swiper/css';
import BlogHeroSlider from './blog-hero-slider';

function BlogHeroSwiper({ postData }) {
  return (
    <div className="swiper-wrap">
      <Swiper
        className="blog-hero-swiper d-none d-md-block"
        effect="coverflow"
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          scale: 1,
        }}
        centeredSlides
        loop
        modules={[Keyboard, Pagination, EffectCoverflow, Autoplay]}
        pagination={{ dynamicBullets: true, clickable: true }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        spaceBetween={24}
        slidesPerView={2.1}
        keyboard={{ enabled: true }}>
        {postData.map((post, index) => (
          <SwiperSlide>
            <BlogHeroCard key={post.node + index} index={index} post={post.node} />
          </SwiperSlide>
        ))}
      </Swiper>
      <BlogHeroSlider postData={postData} />
    </div>
  );
}

export default BlogHeroSwiper;
