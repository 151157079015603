import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import CtaDeck from '../components/common/cta-deck';
import BlogHeroSlider from '../components/blog/blog-hero-slider';
import BlogList from '../components/blog/blog-list';
import BlogHeroSwiper from '../components/blog/blog-hero-swiper';

const headMeta = {
  title: 'Immigration Law Services - Blog | Pandev Law',
  description: 'Blog | Pandev Law',
  keywords:
    'immigration law firm USA,immigration lawyers,immigration law services,immigration attorney,immigration legal services',
};

const schema = '{}';

function Blog({ location, data }) {
  const { heroPostData } = data;

  const heroPosts = heroPostData.edges;

  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      {/* <BlogHeroSlider postData={heroPosts} /> */}
      <BlogHeroSwiper postData={heroPosts} />
      <BlogList />
      <CtaDeck />
    </Layout>
  );
}

export const query = graphql`
  fragment BlogLandingHeroPost on WpCategory {
    databaseId
    ... on WpCategory {
      id
      name
      slug
      posts {
        nodes {
          id: databaseId
          title
          date
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    layout: FULL_WIDTH
                  )
                }
              }
              altText
            }
          }
          slug
          excerpt
        }
      }
    }
  }

  query BlogLandingQuery {
    heroPostData: allWpPost(
      filter: { tags: { nodes: { elemMatch: { slug: { eq: "featured" } } } } }
      sort: { date: DESC }
    ) {
      edges {
        node {
          id: databaseId
          title
          date
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    layout: FULL_WIDTH
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
              }
              altText
            }
          }
          author {
            node {
              name
            }
          }
          tags {
            nodes {
              id
              uri
              name
              slug
            }
          }
          categories {
            nodes {
              name
              id: databaseId
            }
          }
          slug
          excerpt
        }
      }
    }
    blogIndexPage: wpMenu(databaseId: { eq: 783 }) {
      name
      menuItems {
        nodes {
          path
          connectedNode {
            node {
              ...BlogLandingHeroPost
            }
          }
        }
      }
    }
  }
`;

export default Blog;
